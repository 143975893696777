<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">기본정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>회원ID</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.accountId }}</span>
          </td>
        </tr>
        <tr>
          <th>계정상태</th>
          <td colspan="3">
            <StatusSelect
              :dataList="'user_status_name' | getSelectDataList"
              :status="viewModel.model.status"/>
          </td>
        </tr>
        <tr>
          <th>계정상태 업데이트 일시</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.status_update_at | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </td>
        </tr>
        <tr>
          <th>가입일시</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.registeredAt | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </td>
        </tr>
        <tr>
          <th>회원이름</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.name }}</span>
          </td>
          <th>휴대전화 번호</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.phone_number }}</span>
          </td>
          <!-- <th>e-mail</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.email }}</span>
          </td> -->
        </tr>
        <tr>
          <th>회원구분</th>
          <td :colspan="viewModel.model.is_under_fourteen ? 1 : 3">
            <!-- {{ viewModel.model.role | convertIdToText('user_role') }} -->
            <StatusSelect
              :dataList="'user_role_detail' | getSelectDataList"
              :status="viewModel.model.role"/>
          </td>
          <template v-if="viewModel.model.is_under_fourteen">
            <th>법정대리인 가입 동의</th>
            <td>회원 ID: {{ viewModel.model.legal_representation_id }} ({{ viewModel.model.legal_representation_update_at | convertDateFormat('YYYY-MM-DD') }} 동의)</td>
          </template>
        </tr>
        <tr>
          <th>그룹 현황</th>
          <td colspan="3">
            <GroupBoxWidget>
              <BoxInfo
                title="대표"
                :grid="20"
                :isActive="isSelf(familyLeaderMember)"
                :value="setMemberData(familyLeaderMember)"
                emptyValueText="⚠️ empty"/>
              <BoxInfo
                title="멘토/일반"
                :grid="20"
                :isActive="isSelf(otherUnLeaderMember)"
                :value="setMemberData(otherUnLeaderMember)"
                emptyValueText="⚠️ empty"/>
            </GroupBoxWidget>
            <GroupBoxWidget v-if="viewModel.model.member_list.length > 0">
              <BoxInfo
                v-for="idx in 5"
                :key="`child${idx}`"
                :title="`멘티${ idx }`"
                :grid="20"
                :isActive="isSelf(viewModel.model.member_list.child_list[idx-1])"
                :value="setMemberData(viewModel.model.member_list.child_list[idx-1])"
                emptyValueText="-"/>
            </GroupBoxWidget>
          </td>
        </tr>
      </template>
    </TableView>
    <Board
      v-if="viewModel.model.role === 'CHILD'"
      :boardData="viewModel.userBoardData.CARD.boardData"
      :searchParams.sync="viewModel.userBoardData.CARD.searchParams"
      :dataList="viewModel.model.card_list"
      :paginationData="viewModel.userBoardData.CARD.paginationData"
      @onClickRow="rowId => viewModel.onClickRow(rowId)">
    </Board>
    <Board
      :boardData="viewModel.userBoardData.CARD_APPLY.boardData"
      :searchParams.sync="viewModel.userBoardData.CARD_APPLY.searchParams"
      :dataList="viewModel.model.card_apply_list"
      :paginationData="viewModel.userBoardData.CARD_APPLY.paginationData"
      @onClickRow="rowId => viewModel.onClickRow(rowId)">
    </Board>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">마케팅</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>마케팅 동의여부</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.marketing_consent ? '동의':'동의 안 함' }}</span>
          </td>
        </tr>
        <tr>
          <th>업데이트 일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.marketing_consent_at | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </td>
        </tr>
      </template>
    </TableView>
    <Board
      :boardData="viewModel.userBoardData.CS.boardData"
      :searchParams.sync="viewModel.userBoardData.CS.searchParams"
      :dataList="viewModel.model.cs_list"
      :paginationData="viewModel.userBoardData.CS.paginationData"
      @onClickRow="rowId => viewModel.onClickRow(rowId)">
    </Board>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="SERVICE_USER_PLUS_LIST"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import StatusSelect from '@lemontree-ai/lemontree-admin-common-front/components/common/statusSelect/StatusSelect';
import GroupBoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/GroupBoxWidget';
import BoxInfo from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/BoxInfo';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import UserDetailViewModel from '@/views/service/user/viewModel/UserDetailViewModel'

export default {
  name:'userDetail',
  components:{
    PageWithLayout,
    TableView,
    StatusSelect,
    GroupBoxWidget,
    BoxInfo,
    Board,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new UserDetailViewModel(),
    }
  },
  computed:{
    familyLeaderMember(){
      // if(this.viewModel.model.member_list.parents_list.length === 0 ){
      //   return
      // }
      // const result = this.viewModel.model.member_list.parents_list.find(item => item.is_family_leader);
      // return result ? result : false
      return false;
    },
    otherUnLeaderMember(){
      // if(this.viewModel.model.member_list.parents_list.length === 0 ){
      //   return
      // }
      // const result = this.viewModel.model.member_list.parents_list.find(item => !item.is_family_leader);
      // return result ? result : false
      return false;
    }
  },
  methods:{
    isSelf(memberData){
      // return memberData && memberData.accountId === this.viewModel.model.accountId;
      return true;
    },
    setMemberData(memberData, emptyDesc){
      if(!memberData) {
        return emptyDesc
      }
      const isSelf = memberData && memberData.accountId === this.viewModel.model.accountId;
      return isSelf ? `${memberData.name}(본인)` : memberData.status
    }
  }
}
</script>